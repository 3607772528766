const COMMENT_START = new RegExp(`(#|\\/\\/|\\{\\/\\*|\\/\\*+|<!--)`)

const createDirectiveRegExp = featureSelector =>
  new RegExp(`${featureSelector}-(next-line|line|start|end|range)({([^}]+)})?`)

const COMMENT_END = new RegExp(`(-->|\\*\\/\\}|\\*\\/)?`)
const DIRECTIVE = createDirectiveRegExp(`(highlight|hide)`)
const HIGHLIGHT_DIRECTIVE = createDirectiveRegExp(`highlight`)
const HIDE_DIRECTIVE = createDirectiveRegExp(`hide`)

const END_DIRECTIVE = {
  highlight: /highlight-end/,
  hide: /hide-end/,
}

const stripComment = line =>
  /**
   * This regexp does the following:
   * 1. Match a comment start, along with the accompanying PrismJS opening comment span tag;
   * 2. Match one of the directives;
   * 3. Match a comment end, along with the accompanying PrismJS closing span tag.
   */
  line.replace(
    new RegExp(
      `\\s*(${COMMENT_START.source})\\s*${DIRECTIVE.source}\\s*(${COMMENT_END.source})`
    ),
    ``
  )

const containsDirective = line =>
  [HIDE_DIRECTIVE, HIGHLIGHT_DIRECTIVE].some(expr => expr.test(line))

/*
 * This function will output the normalized content (stripped of comment directives)
 * alongside a lookup of filtered lines
 * https://github.com/gatsbyjs/gatsby/blob/dad0628f274f1c61853f3177573bb17a79e4a540/packages/gatsby-remark-prismjs/src/directives.js
 */
const normalize = (content, className = ``) => {
  let normalized = []
  const split = content.split(`\n`)

  for (let i = 0; i < split.length; i++) {
    const line = split[i]
    if (containsDirective(line) && className !== `language-no-highlight`) {
      const [, keyword, directive] = line.match(DIRECTIVE)
      switch (directive) {
        case `start`: {
          const endIndex = split
            .slice(i + 1)
            .findIndex(line => END_DIRECTIVE[keyword].test(line))

          const end = endIndex === -1 ? split.length : endIndex + i

          if (keyword === `highlight`) {
            normalized = normalized.concat(
              split.slice(i, end + 1).reduce((merged, line) => {
                const code = stripComment(line)
                if (code) {
                  merged.push({
                    code,
                    highlighted: true,
                  })
                }
                return merged
              }, [])
            )
          }

          i = end
          break
        }
        case `line`: {
          const code = stripComment(line)
          if (keyword === `highlight` && code) {
            normalized.push({
              code,
              highlighted: true,
            })
          }
          break
        }
        case `next-line`: {
          const code = stripComment(line)
          if (keyword === `highlight`) {
            normalized = normalized.concat(
              [
                {
                  code,
                },
                {
                  code: stripComment(split[i + 1]),
                  highlighted: true,
                },
              ].filter(line => line.code)
            )
          } else if (keyword === `hide` && code) {
            normalized.push({
              code,
            })
          }
          i += 1
          break
        }
        default: {
          break
        }
      }
    } else {
      normalized.push({
        code: line,
      })
    }
  }

  return [
    normalized
      .map(({ code }) => code)
      .join(`\n`)
      .trim(),
    normalized.reduce((lookup, { highlighted }, index) => {
      if (highlighted) {
        lookup[index] = true
      }
      return lookup
    }, {}),
  ]
}

export default normalize
